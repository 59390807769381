export const KEY_FOR_THEME_MODE = 'app-theme-mode'

export const SIGNUP_TIMESTAMP = "signup-attempted-timestamp";

export const FIAT_FX_FEE = 0.9975;

export const SELECT_ACTION_ADD_BANK_ACCOUNT = "SELECT_ACTION_ADD_BANK_ACCOUNT"
export const SELECT_ACTION_ADD_WALLET = "SELECT_ACTION_ADD_WALLET"

// Country codes for Kompany
export const KOUNTRY_CODES = [
    {
        "countryCode": "AT",
        "countryName": "Austria"
    },
    {
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "countryCode": "BE",
        "countryName": "Belgium"
    },
    {
        "countryCode": "BG",
        "countryName": "Bulgaria"
    },
    {
        "countryCode": "BY",
        "countryName": "Belarus"
    },
    {
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "countryCode": "CZ",
        "countryName": "Czech Republic"
    },
    {
        "countryCode": "CY",
        "countryName": "Cyprus"
    },
    {
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "countryCode": "DK",
        "countryName": "Denmark"
    },
    {
        "countryCode": "EE",
        "countryName": "Estonia"
    },
    {
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "countryCode": "FI",
        "countryName": "Finland"
    },
    {
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "countryCode": "GG",
        "countryName": "Guernsey"
    },
    {
        "countryCode": "GI",
        "countryName": "Gibraltar"
    },
    {
        "countryCode": "GL",
        "countryName": "Greenland"
    },
    {
        "countryCode": "GR",
        "countryName": "Greece"
    },
    {
        "countryCode": "HK",
        "countryName": "Hongkong"
    },
    {
        "countryCode": "HR",
        "countryName": "Croatia"
    },
    {
        "countryCode": "HU",
        "countryName": "Hungary"
    },
    {
        "countryCode": "ID",
        "countryName": "Indonesia"
    },
    {
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "countryCode": "IE",
        "countryName": "Ireland"
    },
    {
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "countryCode": "JE",
        "countryName": "Jersey"
    },
    {
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "countryCode": "KG",
        "countryName": "Kyrgyzstan"
    },
    {
        "countryCode": "LT",
        "countryName": "Lithuania"
    },
    {
        "countryCode": "LU",
        "countryName": "Luxembourg"
    },
    {
        "countryCode": "LV",
        "countryName": "Latvia"
    },
    {
        "countryCode": "ME",
        "countryName": "Montenegro"
    },
    {
        "countryCode": "MK",
        "countryName": "Macedonia"
    },
    {
        "countryCode": "MT",
        "countryName": "Malta"
    },
    {
        "countryCode": "MY",
        "countryName": "Malaysia"
    },
    {
        "countryCode": "NL",
        "countryName": "Netherlands"
    },
    {
        "countryCode": "NO",
        "countryName": "Norway"
    },
    {
        "countryCode": "NZ",
        "countryName": "New Zealand"
    },
    {
        "countryCode": "PL",
        "countryName": "Poland"
    },
    {
        "countryCode": "SE",
        "countryName": "Sweden"
    },
    {
        "countryCode": "SG",
        "countryName": "Singapore"
    },
    {
        "countryCode": "SI",
        "countryName": "Slovenia"
    },
    {
        "countryCode": "SK",
        "countryName": "Slovakia"
    },
    {
        "countryCode": "TW",
        "countryName": "Taiwan"
    },
    {
        "countryCode": "TH",
        "countryName": "Thailand"
    },
    {
        "countryCode": "UA",
        "countryName": "Ukraine"
    },
    {
        "countryCode": "UK",
        "countryName": "United Kingdom"
    },
    {
        "countryCode": "RU",
        "countryName": "Russia"
    },
    {
        "countryCode": "RO",
        "countryName": "Romania"
    },
    {
        "countryCode": "RS",
        "countryName": "Serbia"
    },
    {
        "countryCode": "KH",
        "countryName": "Cambodia"
    },
    {
        "countryCode": "US",
        "countryName": "United States of America"
    },
    {
        "countryCode": "US-AL",
        "countryName": "Alabama"
    },
    {
        "countryCode": "US-AK",
        "countryName": "Alaska"
    },
    {
        "countryCode": "US-AR",
        "countryName": "Arkansas"
    },
    {
        "countryCode": "US-AZ",
        "countryName": "Arizona"
    },
    {
        "countryCode": "US-CA",
        "countryName": "California"
    },
    {
        "countryCode": "US-CO",
        "countryName": "Colorado"
    },
    {
        "countryCode": "US-CT",
        "countryName": "Connecticut"
    },
    {
        "countryCode": "US-DC",
        "countryName": "Washington DC"
    },
    {
        "countryCode": "US-DE",
        "countryName": "Delaware"
    },
    {
        "countryCode": "US-FL",
        "countryName": "Florida"
    },
    {
        "countryCode": "US-GA",
        "countryName": "Georgia"
    },
    {
        "countryCode": "US-HI",
        "countryName": "Hawaii"
    },
    {
        "countryCode": "US-ID",
        "countryName": "Idaho"
    },
    {
        "countryCode": "US-IL",
        "countryName": "Illinois"
    },
    {
        "countryCode": "US-IN",
        "countryName": "Indiana"
    },
    {
        "countryCode": "US-IA",
        "countryName": "Iowa"
    },
    {
        "countryCode": "US-KS",
        "countryName": "Kansas"
    },
    {
        "countryCode": "US-KY",
        "countryName": "Kentucky"
    },
    {
        "countryCode": "US-LA",
        "countryName": "Louisiana"
    },
    {
        "countryCode": "US-MA",
        "countryName": "Massachusetts"
    },
    {
        "countryCode": "US-MD",
        "countryName": "Maryland"
    },
    {
        "countryCode": "US-ME",
        "countryName": "Maine"
    },
    {
        "countryCode": "US-MI",
        "countryName": "Michigan"
    },
    {
        "countryCode": "US-MN",
        "countryName": "Minnesota"
    },
    {
        "countryCode": "US-MO",
        "countryName": "Missouri"
    },
    {
        "countryCode": "US-MS",
        "countryName": "Mississippi"
    },
    {
        "countryCode": "US-MT",
        "countryName": "Montana"
    },
    {
        "countryCode": "US-NC",
        "countryName": "North Carolina"
    },
    {
        "countryCode": "US-ND",
        "countryName": "North Dakota"
    },
    {
        "countryCode": "US-NH",
        "countryName": "New Hampshire"
    },
    {
        "countryCode": "US-NJ",
        "countryName": "New Jersey"
    },
    {
        "countryCode": "US-NM",
        "countryName": "New Mexico"
    },
    {
        "countryCode": "US-NV",
        "countryName": "Nevada"
    },
    {
        "countryCode": "US-NY",
        "countryName": "New York"
    },
    {
        "countryCode": "US-OH",
        "countryName": "Ohio"
    },
    {
        "countryCode": "US-OK",
        "countryName": "Oklahoma"
    },
    {
        "countryCode": "US-OR",
        "countryName": "Oregon"
    },
    {
        "countryCode": "US-PA",
        "countryName": "Pennsylvania"
    },
    {
        "countryCode": "US-RI",
        "countryName": "Rhode Island"
    },
    {
        "countryCode": "US-SC",
        "countryName": "South Carolina"
    },
    {
        "countryCode": "US-SD",
        "countryName": "South Dakota"
    },
    {
        "countryCode": "US-TN",
        "countryName": "Tennessee"
    },
    {
        "countryCode": "US-TX",
        "countryName": "Texas"
    },
    {
        "countryCode": "US-UT",
        "countryName": "Utah"
    },
    {
        "countryCode": "US-VA",
        "countryName": "Virginia"
    },
    {
        "countryCode": "US-VT",
        "countryName": "Vermont"
    },
    {
        "countryCode": "US-WA",
        "countryName": "Washington"
    },
    {
        "countryCode": "US-WI",
        "countryName": "Wisconsin"
    },
    {
        "countryCode": "US-WV",
        "countryName": "West Virginia"
    },
    {
        "countryCode": "US-WY",
        "countryName": "Wyoming"
    },
    {
        "countryCode": "XK",
        "countryName": "Kosovo"
    },
    {
        "countryCode": "KR",
        "countryName": "South Korea"
    }
]

export const ORIGINATION_PHONE_EXTENSIONS = [
    // {
    //   "code": "+93",
    //   "name": "Afghanistan"
    // },
    // {
    //   "code": "+355",
    //   "name": "Albania"
    // },
    {
      "code": "+213",
      "name": "Algeria"
    },
    {
      "code": "+376",
      "name": "Andorra"
    },
    // {
    //   "code": "+244",
    //   "name": "Angola"
    // },
    {
      "code": "+54",
      "name": "Argentina"
    },
    {
      "code": "+374",
      "name": "Armenia"
    },
    {
      "code": "+297",
      "name": "Aruba"
    },
    {
      "code": "+61",
      "name": "Australia"
    },
    // {
    //   "code": "+672",
    //   "name": "Australian External Territories"
    // },
    {
      "code": "+43",
      "name": "Austria"
    },
    {
      "code": "+994",
      "name": "Azerbaijan"
    },
    {
      "code": "+973",
      "name": "Bahrain"
    },
    {
      "code": "+880",
      "name": "Bangladesh"
    },
    // {
    //   "code": "+375",
    //   "name": "Belarus"
    // },
    {
      "code": "+32",
      "name": "Belgium"
    },
    {
      "code": "+501",
      "name": "Belize"
    },
    // {
    //   "code": "+229",
    //   "name": "Benin"
    // },
    // {
    //   "code": "+975",
    //   "name": "Bhutan"
    // },
    {
      "code": "+591",
      "name": "Bolivia"
    },
    {
      "code": "+387",
      "name": "Bosnia and Herzegovina"
    },
    // {
    //   "code": "+267",
    //   "name": "Botswana"
    // },
    {
      "code": "+55",
      "name": "Brazil"
    },
    {
      "code": "+246",
      "name": "British Indian Ocean Territory"
    },
    {
      "code": "+673",
      "name": "Brunei"
    },
    {
      "code": "+359",
      "name": "Bulgaria"
    },
    // {
    //   "code": "+226",
    //   "name": "Burkina Faso"
    // },
    {
      "code": "+257",
      "name": "Burundi"
    },
    // {
    //   "code": "+855",
    //   "name": "Cambodia"
    // },
    // {
    //   "code": "+237",
    //   "name": "Cameroon"
    // },
    {
      "code": "+1",
      "name": "Canada"
    },
    // {
    //   "code": "+238",
    //   "name": "Cape Verde"
    // },
    // {
    //   "code": "+236",
    //   "name": "Central African Republic"
    // },
    {
      "code": "+235",
      "name": "Chad"
    },
    {
      "code": "+56",
      "name": "Chile"
    },
    {
      "code": "+86",
      "name": "China"
    },
    // {
    //   "code": "+61",
    //   "name": "Christmas Island"
    // },
    // {
    //   "code": "+61",
    //   "name": "Cocos-Keeling Islands"
    // },
    {
      "code": "+57",
      "name": "Colombia"
    },
    {
      "code": "+269",
      "name": "Comoros"
    },
    // {
    //   "code": "+242",
    //   "name": "Congo"
    // },
    // {
    //   "code": "+243",
    //   "name": "Congo, Dem. Rep. of (Zaire)"
    // },
    {
      "code": "+682",
      "name": "Cook Islands"
    },
    {
      "code": "+506",
      "name": "Costa Rica"
    },
    {
      "code": "+385",
      "name": "Croatia"
    },
    // {
    //   "code": "+53",
    //   "name": "Cuba"
    // },
    {
      "code": "+420",
      "name": "Czech Republic"
    },
    {
      "code": "+45",
      "name": "Denmark"
    },
    {
      "code": "+246",
      "name": "Diego Garcia"
    },
    {
      "code": "+253",
      "name": "Djibouti"
    },
    {
      "code": "+670",
      "name": "East Timor"
    },
    {
      "code": "+56",
      "name": "Easter Island"
    },
    {
      "code": "+593",
      "name": "Ecuador"
    },
    {
      "code": "+20",
      "name": "Egypt"
    },
    {
      "code": "+503",
      "name": "El Salvador"
    },
    {
      "code": "+240",
      "name": "Equatorial Guinea"
    },
    // {
    //   "code": "+291",
    //   "name": "Eritrea"
    // },
    // {
    //   "code": "+372",
    //   "name": "Estonia"
    // },
    // {
    //   "code": "+251",
    //   "name": "Ethiopia"
    // },
    {
      "code": "+500",
      "name": "Falkland Islands"
    },
    {
      "code": "+298",
      "name": "Faroe Islands"
    },
    {
      "code": "+679",
      "name": "Fiji"
    },
    {
      "code": "+358",
      "name": "Finland"
    },
    {
      "code": "+33",
      "name": "France"
    },
    {
      "code": "+596",
      "name": "French Antilles"
    },
    {
      "code": "+594",
      "name": "French Guiana"
    },
    {
      "code": "+689",
      "name": "French Polynesia"
    },
    {
      "code": "+241",
      "name": "Gabon"
    },
    {
      "code": "+220",
      "name": "Gambia"
    },
    {
      "code": "+995",
      "name": "Georgia"
    },
    {
      "code": "+49",
      "name": "Germany"
    },
    // {
    //   "code": "+233",
    //   "name": "Ghana"
    // },
    {
      "code": "+350",
      "name": "Gibraltar"
    },
    {
      "code": "+30",
      "name": "Greece"
    },
    {
      "code": "+299",
      "name": "Greenland"
    },
    {
      "code": "+590",
      "name": "Guadeloupe"
    },
    {
      "code": "+502",
      "name": "Guatemala"
    },
    // {
    //   "code": "+224",
    //   "name": "Guinea"
    // },
    // {
    //   "code": "+245",
    //   "name": "Guinea-Bissau"
    // },
    {
      "code": "+595",
      "name": "Guyana"
    },
    // {
    //   "code": "+509",
    //   "name": "Haiti"
    // },
    {
      "code": "+504",
      "name": "Honduras"
    },
    {
      "code": "+852",
      "name": "Hong Kong"
    },
    {
      "code": "+36",
      "name": "Hungary"
    },
    {
      "code": "+354",
      "name": "Iceland"
    },
    {
      "code": "+91",
      "name": "India"
    },
    {
      "code": "+62",
      "name": "Indonesia"
    },
    // {
    //   "code": "+98",
    //   "name": "Iran"
    // },
    // {
    //   "code": "+964",
    //   "name": "Iraq"
    // },
    {
      "code": "+353",
      "name": "Ireland"
    },
    {
      "code": "+972",
      "name": "Israel"
    },
    {
      "code": "+39",
      "name": "Italy"
    },
    // {
    //   "code": "+225",
    //   "name": "Ivory Coast"
    // },
    {
      "code": "+81",
      "name": "Japan"
    },
    // {
    //   "code": "+962",
    //   "name": "Jordan"
    // },
    {
      "code": "+254",
      "name": "Kenya"
    },
    {
      "code": "+686",
      "name": "Kiribati"
    },
    {
      "code": "+965",
      "name": "Kuwait"
    },
    // {
    //   "code": "+996",
    //   "name": "Kyrgyzstan"
    // },
    {
      "code": "+856",
      "name": "Laos"
    },
    {
      "code": "+371",
      "name": "Latvia"
    },
    // {
    //   "code": "+961",
    //   "name": "Lebanon"
    // },
    {
      "code": "+266",
      "name": "Lesotho"
    },
    // {
    //   "code": "+231",
    //   "name": "Liberia"
    // },
    // {
    //   "code": "+218",
    //   "name": "Libya"
    // },
    {
      "code": "+423",
      "name": "Liechtenstein"
    },
    {
      "code": "+370",
      "name": "Lithuania"
    },
    {
      "code": "+352",
      "name": "Luxembourg"
    },
    {
      "code": "+853",
      "name": "Macau SAR China"
    },
    {
      "code": "+389",
      "name": "Macedonia"
    },
    // {
    //   "code": "+261",
    //   "name": "Madagascar"
    // },
    // {
    //   "code": "+265",
    //   "name": "Malawi"
    // },
    {
      "code": "+60",
      "name": "Malaysia"
    },
    {
      "code": "+960",
      "name": "Maldives"
    },
    // {
    //   "code": "+223",
    //   "name": "Mali"
    // },
    // {
    //   "code": "+356",
    //   "name": "Malta"
    // },
    {
      "code": "+692",
      "name": "Marshall Islands"
    },
    {
      "code": "+596",
      "name": "Martinique"
    },
    // {
    //   "code": "+222",
    //   "name": "Mauritania"
    // },
    {
      "code": "+230",
      "name": "Mauritius"
    },
    {
      "code": "+262",
      "name": "Mayotte"
    },
    {
      "code": "+52",
      "name": "Mexico"
    },
    {
      "code": "+691",
      "name": "Micronesia"
    },
    {
      "code": "+373",
      "name": "Moldova"
    },
    {
      "code": "+377",
      "name": "Monaco"
    },
    {
      "code": "+976",
      "name": "Mongolia"
    },
    {
      "code": "+382",
      "name": "Montenegro"
    },
    {
      "code": "+1664",
      "name": "Montserrat"
    },
    // {
    //   "code": "+212",
    //   "name": "Morocco"
    // },
    // {
    //   "code": "+95",
    //   "name": "Myanmar"
    // },
    {
      "code": "+264",
      "name": "Namibia"
    },
    {
      "code": "+674",
      "name": "Nauru"
    },
    {
      "code": "+977",
      "name": "Nepal"
    },
    {
        "code": "+1671",
        "name": "Guam"
    },
    {
      "code": "+31",
      "name": "Netherlands"
    },
    {
      "code": "+687",
      "name": "New Caledonia"
    },
    {
      "code": "+64",
      "name": "New Zealand"
    },
    // {
    //   "code": "+505",
    //   "name": "Nicaragua"
    // },
    // {
    //   "code": "+227",
    //   "name": "Niger"
    // },
    {
      "code": "+234",
      "name": "Nigeria"
    },
    {
      "code": "+683",
      "name": "Niue"
    },
    {
      "code": "+672",
      "name": "Norfolk Island"
    },
    // // {
    // //   "code": "+850",
    // //   "name": "North Korea"
    // // },
    {
      "code": "+47",
      "name": "Norway"
    },
    // {
    //   "code": "+968",
    //   "name": "Oman"
    // },
    {
      "code": "+92",
      "name": "Pakistan"
    },
    {
      "code": "+680",
      "name": "Palau"
    },
    {
      "code": "+970",
      "name": "Palestinian Territory"
    },
    // {
    //   "code": "+507",
    //   "name": "Panama"
    // },
    // {
    //   "code": "+675",
    //   "name": "Papua New Guinea"
    // },
    {
      "code": "+595",
      "name": "Paraguay"
    },
    {
      "code": "+51",
      "name": "Peru"
    },
    {
      "code": "+63",
      "name": "Philippines"
    },
    {
      "code": "+48",
      "name": "Poland"
    },
    {
      "code": "+351",
      "name": "Portugal"
    },
    {
      "code": "+974",
      "name": "Qatar"
    },
    {
      "code": "+262",
      "name": "Reunion"
    },
    {
      "code": "+40",
      "name": "Romania"
    },
    // {
    //   "code": "+7",
    //   "name": "Russia"
    // },
    {
      "code": "+250",
      "name": "Rwanda"
    },
    {
      "code": "+685",
      "name": "Samoa"
    },
    {
      "code": "+378",
      "name": "San Marino"
    },
    {
      "code": "+966",
      "name": "Saudi Arabia"
    },
    // {
    //   "code": "+221",
    //   "name": "Senegal"
    // },
    {
      "code": "+381",
      "name": "Serbia"
    },
    {
      "code": "+248",
      "name": "Seychelles"
    },
    // {
    //   "code": "+232",
    //   "name": "Sierra Leone"
    // },
    {
      "code": "+65",
      "name": "Singapore"
    },
    {
      "code": "+421",
      "name": "Slovakia"
    },
    {
      "code": "+386",
      "name": "Slovenia"
    },
    {
      "code": "+677",
      "name": "Solomon Islands"
    },
    {
      "code": "+27",
      "name": "South Africa"
    },
    {
      "code": "+500",
      "name": "South Georgia and the South Sandwich Islands"
    },
    {
      "code": "+82",
      "name": "South Korea"
    },
    {
      "code": "+34",
      "name": "Spain"
    },
    // {
    //   "code": "+94",
    //   "name": "Sri Lanka"
    // },
    // {
    //   "code": "+249",
    //   "name": "Sudan"
    // },
    {
      "code": "+597",
      "name": "Suriname"
    },
    {
      "code": "+268",
      "name": "Swaziland"
    },
    {
      "code": "+46",
      "name": "Sweden"
    },
    {
      "code": "+41",
      "name": "Switzerland"
    },
    // {
    //   "code": "+963",
    //   "name": "Syria"
    // },
    {
      "code": "+886",
      "name": "Taiwan"
    },
    // {
    //   "code": "+992",
    //   "name": "Tajikistan"
    // },
    // {
    //   "code": "+255",
    //   "name": "Tanzania"
    // },
    {
      "code": "+66",
      "name": "Thailand"
    },
    {
      "code": "+670",
      "name": "Timor Leste"
    },
    {
      "code": "+228",
      "name": "Togo"
    },
    {
      "code": "+690",
      "name": "Tokelau"
    },
    // {
    //   "code": "+676",
    //   "name": "Tonga"
    // },
    {
      "code": "+216",
      "name": "Tunisia"
    },
    {
      "code": "+90",
      "name": "Turkey"
    },
    {
      "code": "+993",
      "name": "Turkmenistan"
    },
    {
      "code": "+688",
      "name": "Tuvalu"
    },
    // {
    //   "code": "+256",
    //   "name": "Uganda"
    // },
    {
      "code": "+380",
      "name": "Ukraine"
    },
    {
      "code": "+971",
      "name": "United Arab Emirates"
    },
    {
      "code": "+44",
      "name": "United Kingdom"
    },
    // {
    //   "code": "+1",
    //   "name": "United States"
    // },
    {
      "code": "+598",
      "name": "Uruguay"
    },
    {
      "code": "+998",
      "name": "Uzbekistan"
    },
    {
      "code": "+678",
      "name": "Vanuatu"
    },
    {
      "code": "+58",
      "name": "Venezuela"
    },
    {
      "code": "+84",
      "name": "Vietnam"
    },
    {
      "code": "+681",
      "name": "Wallis and Futuna"
    },
    // {
    //   "code": "+967",
    //   "name": "Yemen"
    // },
    {
      "code": "+260",
      "name": "Zambia"
    },
    {
      "code": "+255",
      "name": "Zanzibar"
    },
    // {
    //   "code": "+263",
    //   "name": "Zimbabwe"
    // }
]

export const COUNTRY_CURRENCY_OVERRIDES = [
    {
        name: 'South Korea',
        currency: 'KRW'
    }
]